<script type="text/javascript" src="https://js.iugu.com/v2"></script>

<template src="./wallet.html"></template>

<script>
// https://www.npmjs.com/package/vue-button-spinner
import ButtonSpinner from "vue-button-spinner";
// https://www.npmjs.com/package/qrcode.vue
import QrcodeVue from "qrcode.vue";
import mixins from "../../../../mixins/mixins.js";

export default {
  mixins: [mixins],
  components: {
    ButtonSpinner,
    QrcodeVue,
  },
  data: () => ({
    window: {
      width: 0,
      height: 0,
    },
    showWebTable: true,
    numOfElements: -1,
    seen: false,
    balance: 0,
    bonusBalance: null,
    blockedBalance: 0,
    bonusStatus: false,
    transactions: [],
    invoices: [],
    value: "",
    payWay: "",
    pixChargeTax: 0,
    cardChargeTax: 0,
    chargeTax: 0,
    user: [
      {
        address: null,
        number: null,
        complement: null,
        cpf: null,
        email: null,
        name: null,
        customerId: null,
      },
    ],
    creditCard: {
      ccMes: "",
      ccAno: "",
      ccCvv: "",
      ccNumero: "",
      ccNome: "",
    },
    paymentInfo: {
      method: "",
      token: "",
      upload_register_id: "", // vm.paymentId:
      description: "RECHARGE",
      email: "",
      amount: "",
      months: 1,
      payer: null,
    },
    invoice: [],
    money: {
      decimal: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false,
    },
    number: "",
    complement: "",
    semNumero: false,
    statusMessage: "",
    isLoading: false,
    status: "",
    totalBalance: 0,
    options: [
      { text: "Cartão", value: "CC", icon: "redit-card" },
      { text: "Pix", value: "Pix" },
    ],
    qrCodePix:
      "00020101021226670014br.gov.bcb.pix2545qr.iugu.com/public/payload/v2/hjrGsrNhXAGfkxo5204000053039865802BR5925CONSULTACLICK SERVICOS SA6009SAO PAULO62070503***63043A07",
    sizeQrCode: 200,
    invalidValues: {
      value: false,
      name: false,
      month: false,
      year: false,
    },
    invalidCardNumber: false,
    invalidCardCVV: false,
    messages: {
      value: "Campo obrigatório",
      name: "Campo obrigatório",
      number: "Campo obrigatório",
      cvv: "Campo obrigatório",
      month: "Campo obrigatório",
      year: "Campo obrigatório",
    },
    numPages: 1,
    currentx: 1,
    pagination: false,
    resultsPaginated: [],
    hideCloseButton: true,
    invalidCreditCard: false,
  }),
  mounted: function () {
    const vm = this;
    vm.Iugu = document.createElement("script");
    vm.Iugu.setAttribute("src", "https://js.iugu.com/v2");
    document.head.appendChild(vm.Iugu);
    vm.init();
  },
  created: function () {
    const vm = this;
    window.addEventListener("resize", vm.handleResize);
    vm.handleResize();
  },
  destroyed: function () {
    const vm = this;
    window.removeEventListener("resize", vm.handleResize);
  },
  watch: {
    currentx: function () {
      const vm = this;
      vm.changePagination();
    },
  },
  methods: {
    clearButtonLoading() {
      setTimeout(() => {
        this.isLoading = false;
        this.statusMessage = "";
        this.status = "";
      }, 1600);
    },
    init() {
      const vm = this;
      vm.user.address = vm.$session.get("address");
      vm.user.cpf = vm.$session.get("cpf");
      vm.user.email = vm.$session.get("email");
      vm.user.name = vm.$session.get("name");
      vm.user.customerId = vm.$session.get("customerId");
      vm.number = vm.user.address.number;
      vm.complement = vm.user.address.complement;
      vm.getWalletOverview();
      vm.getInvoices();
      vm.getCartaoChargeTax();
      vm.getPixChargeTax();
    },
    goToInvoiceIugu(link) {
      const vm = this;
      window.open(link);
    },
    cleanForm() {
      const vm = this;
      vm.value = null;
      vm.payWay = "";
      vm.invalidCreditCard = false;
      vm.creditCard.ccNome = "";
      vm.creditCard.ccNumero = "";
      vm.creditCard.ccCvv = "";
      vm.creditCard.ccMes = "";
      vm.creditCard.ccAno = "";
      vm.invalidValues = {
        value: false,
        name: false,
        number: false,
        cvv: false,
        month: false,
        year: false,
      };
      vm.messages = {
        value: "Campo obrigatório",
        name: "Campo obrigatório",
        number: "Campo obrigatório",
        cvv: "Campo obrigatório",
        month: "Campo obrigatório",
        year: "Campo obrigatório",
      };
    },
    openModalRecharge() {
      const vm = this;
      vm.cleanForm();
      vm.$bvModal.show("modal-wallet-recharge");
    },
    handleResize() {
      const vm = this;
      vm.window.width = window.innerWidth;
      vm.window.height = window.innerHeight;
      if (vm.window.width <= 414) {
        vm.showWebTable = false;
        vm.sizeQrCode = 130;
      } else {
        vm.showWebTable = true;
        vm.sizeQrCode = 200;
      }
    },
    getWalletOverview() {
      const vm = this;
      vm.axios
        .get(vm.$store.state.filooServer + "wallet/overview", {
          headers: { Authorization: vm.$session.get("bearer") },
        })
        .then(function (response) {
          vm.balance = response.data.avaliableBalance;
          vm.bonusBalance = response.data.bonusBalance;
          vm.blockedBalance = response.data.blockedBalance;
          vm.transactions = response.data.transactions;
          vm.bonusStatus = response.data.bonusStatus;
          vm.showMore();
          if (vm.testNullUndefinedEmpty(response.data.bonusBalance)) {
            vm.totalBalance = vm.balance;
          } else {
            vm.totalBalance = vm.balance + vm.bonusBalance;
          }
        });
    },

    getPixChargeTax() {
      const vm = this;
      vm.axios
        .get(vm.$store.state.filooServer + "parameters/tax/pix_charge_tax_percentage", {
          headers: { Authorization: vm.$session.get("bearer") },
        })
        .then(function (response) {
          vm.pixChargeTax = parseFloat(response.data);
        });
    },

    getCartaoChargeTax() {
      const vm = this;
      vm.axios
        .get(vm.$store.state.filooServer + "parameters/tax/card_charge_tax_percentage", {
          headers: { Authorization: vm.$session.get("bearer") },
        })
        .then(function (response) {
          vm.cardChargeTax = parseFloat(response.data);
        });
    },

    getInvoices() {
      const vm = this;
      vm.axios
        .get(
          vm.$store.state.filooServer +
            "payment/get-customer-signature-invoices/" +
            vm.$session.get("id"),
          {
            headers: { Authorization: vm.$session.get("bearer") },
          }
        )
        .then(function (response) {
          vm.invoices = response.data;

          for (let inv in vm.invoices) {
            let datePaid = new Date(vm.invoices[inv].paid_at);
            vm.invoices[inv].paid_at = datePaid.toLocaleString("en-GB");
          }
        });
    },

    initIUGU() {
      let vm = this;
      Iugu.setAccountID(vm.$store.state.iuguNotifyAccountId);
      Iugu.setup();
      Iugu.setTestMode(vm.$store.state.iuguTestMode);
    },
    checkFields() {
      let vm = this;
      vm.isLoading = true;
      let fieldsAreValid = true;
      let d = new Date();
      let year = d.getFullYear() + "";
      let yearDigits = parseInt(year.slice(2, 4), 10);
      vm.invalidCreditCard = false;
      if (vm.testNullUndefinedEmpty(vm.value) || parseFloat(vm.value) < 10) {
        fieldsAreValid = false;
        vm.invalidValues.value = true;
        vm.messages.value = vm.testNullUndefinedEmpty(vm.value)
          ? "Campo obrigatório"
          : "O valor mínimo de recarga é R$ 10,00";
      }
      if (
        vm.testNullUndefinedEmpty(vm.creditCard.ccNome) ||
        /\d/.test(vm.creditCard.ccNome)
      ) {
        fieldsAreValid = false;
        vm.invalidValues.name = true;
        vm.messages.name = vm.testNullUndefinedEmpty(vm.creditCard.ccNome)
          ? "Campo obrigatório"
          : "Nome inválido";
      }
      if (vm.validateCardNumber() === false) {
        fieldsAreValid = false;
      }
      if (
        vm.testNullUndefinedEmpty(vm.creditCard.ccMes) ||
        parseInt(vm.creditCard.ccMes, 10) > 12 ||
        parseInt(vm.creditCard.ccMes, 10) == 0
      ) {
        fieldsAreValid = false;
        vm.invalidValues.month = true;
        vm.messages.month = vm.testNullUndefinedEmpty(vm.creditCard.ccMes)
          ? "Campo obrigatório"
          : "Mês inválido";
      }
      if (
        vm.testNullUndefinedEmpty(vm.creditCard.ccAno) ||
        parseInt(vm.creditCard.ccAno, 10) < yearDigits
      ) {
        fieldsAreValid = false;
        vm.invalidValues.year = true;
        vm.messages.year = vm.testNullUndefinedEmpty(vm.creditCard.ccAno)
          ? "Campo obrigatório"
          : "Ano inválido";
      }
      if (fieldsAreValid) {
        vm.statusMessage = "";
        vm.payCartao();
      } else {
        vm.statusMessage = "Campos não preenchidos ou inválidos";
        vm.clearButtonLoading();
      }
    },
    validateCardNumber() {
      const vm = this;
      const regexDiners = /^3(?:0[0-5]|[68][0-9])/;
      const regexCardAmex = /^3[47]/;
      const testDiners = regexDiners.test(
        vm.creditCard.ccNumero.toString().trim()
      );
      const testAmex = regexCardAmex.test(
        vm.creditCard.ccNumero.toString().trim()
      );
      let isDinersOrAmex = false;
      let valid = true;
      if (vm.testNullUndefinedEmpty(vm.creditCard.ccNumero)) {
        vm.invalidValues.number = true;
        vm.messages.number = "Campo obrigatório";
        valid = false;
      }
      if (testDiners) {
        isDinersOrAmex = true;
        if (vm.creditCard.ccNumerolength < 15) {
          vm.invalidCardNumber = true;
          vm.messages.number = "Número do cartão inválido";
          valid = false;
        }
        if (vm.testNullUndefinedEmpty(vm.creditCard.ccCvv) === false) {
          if (vm.creditCard.ccCvv.length < 4) {
            vm.invalidCardCVV = true;
            vm.messages.cvv = "Código inválido";
            valid = false;
          }
        } else {
          vm.invalidCardCVV = true;
          vm.messages.cvv = "Campo obrigatório";
          valid = false;
        }
      }
      if (testAmex) {
        isDinersOrAmex = true;
        if (vm.creditCard.ccNumero.length < 17) {
          vm.invalidCardNumber = true;
          vm.messages.number = "Número do cartão inválido";
          valid = false;
        }
        if (vm.testNullUndefinedEmpty(vm.creditCard.ccCvv) === false) {
          if (vm.creditCard.ccCvv.length < 4) {
            vm.invalidCardCVV = true;
            vm.messages.cvv = "Código inválido";
            valid = false;
          }
        } else {
          vm.invalidCardCVV = true;
          vm.messages.cvv = "Campo obrigatório";
          valid = false;
        }
      }
      if (isDinersOrAmex === false) {
        if (vm.creditCard.ccNumero.length < 19) {
          vm.invalidCardNumber = true;
          vm.messages.number = "Número do cartão inválido";
          valid = false;
        }
        if (vm.testNullUndefinedEmpty(vm.creditCard.ccCvv) === false) {
          if (vm.creditCard.ccCvv.length < 3) {
            vm.invalidCardCVV = true;
            vm.messages.cvv = "Código inválido";
            valid = false;
          }
        } else {
          vm.invalidCardCVV = true;
          vm.messages.cvv = "Campo obrigatório";
          valid = false;
        }
      }
      return valid;
    },
    payCartao() {
      this.paymentInfo.method = "credit_card";
      this.paymentInfo.months = 1;
      this.paymentInfo.email = this.user.email;
      this.paymentInfo.amount = this.value;
      this.paymentInfo.payer = {
          name: this.user.name,
          cpf_cnpj: this.user.cpf,
          address: this.user.address,
        },
      this.initIUGU();
      const full = this.creditCard.ccNome.split(" ");
      
      const iuguCreditCard = Iugu.CreditCard(
        this.creditCard.ccNumero,
        this.creditCard.ccMes,
        this.creditCard.ccAno,
        full[0],
        full.pop(),
        this.creditCard.ccCvv
      );

      if (iuguCreditCard.valid()) {
        Iugu.createPaymentToken(iuguCreditCard.toData(), (response) => {
          if (response && response.id) {
            this.paymentInfo.token = response.id;
            this.axios
              .post(
                this.$store.state.filooServer + "payment/charge",
                this.paymentInfo,
                {
                  headers: {
                    Authorization: this.$session.get("bearer"),
                  },
                }
              )
              .then((responsee) => {
                if (responsee.data.status == "Autorizado") {
                  this.showNotifySuccess(
                    "Operação realizada",
                    "Recarga feita com sucesso.",
                    3000
                  );
                  this.clearButtonLoading();
                  this.$bvModal.hide("modal-wallet-recharge");
                  this.$bvModal.show("modal-wallet-pay-card");
                  this.setaOSaldo();
                  this.getWalletOverview();
                } else {
                  this.showError(responsee);
                }
              })
              .catch((error)=> {
                this.showError(error);
              });

          } else {
            this.clearButtonLoading();        
            this.showNotifyError(
              "Erro ao validar cartão, não foi possível criar o token na Iugu",
              6000
            );
          }
        });
      } else {
        this.clearButtonLoading();       
        this.invalidCreditCard = true;
        this.$bvModal.show("modal-invalid-credit-card");
      }
    },
    showError(responsee) {      
        this.clearButtonLoading();
        this.invalidCreditCard = true;
        this.$bvModal.show("modal-invalid-credit-card");          
    },
    setaOSaldo() {
      const vm = this;
      vm.axios
        .get(this.$store.state.filooServer + "wallet/overview", {
          headers: {
            Authorization: this.$session.get("bearer"),
          },
        })
        .then(function (response) {
          vm.saldo = response.data.avaliableBalance;
          vm.$session.set("saldo", vm.saldo);
          vm.balance = vm.saldo;
          vm.$store.commit(
            "UPDATE_CUSTOMER_BALANCE",
            !vm.$store.state.updatedCustomerBalance
          );
        });
    },

    showMore() {
      const vm = this;
      vm.axios
        .get(vm.$store.state.filooServer + "wallet/transactions", {
          headers: { Authorization: vm.$session.get("bearer") },
        })
        .then(function (response) {
          if (vm.numOfElements == response.data.numberOfElements) {
            vm.seen = true;
          }
          vm.numOfElements = response.data.numberOfElements;
          vm.transactions = response.data.content;
          vm.numPages = Math.round(vm.transactions.length / 9);
          vm.pagination = vm.numPages > 1;
          vm.changePagination();
        })
        .catch(function (error) {
          console.error({ error_showMore: error });
        });
    },

    getPatientId() {
      const vm = this;
      vm.axios
        .get(
          vm.$store.state.filooServer + "company/cpfEmployees/" + vm.user.cpf,
          {
            headers: { Authorization: vm.$session.get("bearer") },
          }
        )
        .then(function (response) {
          vm.user.id = response.data;
        })
        .catch(function (error) {
          console.error({ error_getPatientId: error });
        });
    },
    postRechargePix() {
      const vm = this;
      vm.$bvModal.hide("modal-wallet-pix");
      vm.isLoading = true;
      let payload = {
        amount: vm.value,
        email: vm.user.email,
        method: "pix",
        months: 1,
        payer: {
          name: vm.user.name,
          cpf_cnpj: vm.user.cpf,
          address: vm.user.address,
        },
        token: null,
      };
      vm.axios
        .post(vm.$store.state.filooServer + "payment/charge", payload, {
          headers: { Authorization: vm.$session.get("bearer") },
        })
        .then(function (response) {
          vm.isLoading = false;
          if (response.status === 200) {
            vm.invoice = response.data;
            if (!vm.testNullUndefinedEmpty(vm.invoice.pix_code)) {
              vm.qrCodePix = vm.invoice.pix_code;
              vm.clearButtonLoading();
              vm.$bvModal.show("modal-wallet-pix");
              vm.setaOSaldo();
              vm.getWalletOverview();
            } else {
              vm.clearButtonLoading();
              vm.$vs.notify({
                title: "Atenção!",
                text: "Não foi possível realizar a recarga via pix. Entre em contato com a equipe de atendimento para informar o ocorrido através do e-mail: contato@filoo.com.br ou pelo whatsapp 11 99650-7610.",
                color: "danger",
                position: "top-right",
              });
            }
          }
        })
        .catch(function (error) {
          console.error({ chargePix_catch: error });
          vm.$bvModal.show("modal-wallet-pix");
          vm.isLoading = false;
          vm.clearButtonLoading();
          vm.$vs.notify({
            title: "Atenção!",
            text: "Não foi possível realizar a recarga via pix. Entre em contato com a equipe de atendimento para informar o ocorrido através do e-mail: contato@filoo.com.br ou pelo whatsapp 11 99650-7610.",
            color: "danger",
            position: "top-right",
          });
        });
    },
    copyAndWrite() {
      const vm = this;
      navigator.clipboard.writeText(vm.qrCodePix);
      vm.$vs.notify({
        title: "",
        text: "Código pix copiado com sucesso!",
        color: "success",
        position: "top-right",
      });
    },

    cancelSubscription() {
      const vm = this;
      vm.$swal({
        title: "Atenção!",
        text: "Deseja mesmo cancelar a sua assinatura?",
        dangerMode: true,
        icon: "warning",
        buttons: {
          cancel: false,
          nao: {
            text: "Não",
            value: "nao",
          },
          sim: {
            text: "Sim",
            value: "sim",
          },
        },
      }).then((value) => {
        switch (value) {
          case "sim":
            vm.axios
              .get(
                vm.$store.state.filooServer +
                  "payment/suspend-signature/" +
                  vm.user.cpf,
                {
                  headers: { Authorization: vm.$session.get("bearer") },
                }
              )
              .then(function (response) {
                vm.$vs.notify({
                  title: "Sucesso!",
                  text: "Sua assinatura foi cancelada com sucesso.",
                  color: "success",
                });
                vm.getInvoices();
              })
              .catch(function (error) {
                vm.$vs.notify({
                  title: "Atenção!",
                  text: "Não foi possível cancelar sua assinatura, contacte o administrador do sistema.",
                  color: "danger",
                });
                console.error({ error_cancelSubscription: error });
              });
            break;
          case "nao":
            break;
        }
      });
    },
    changePagination() {
      let vm = this;
      vm.resultsPaginated =
        vm.currentx === 1
          ? (vm.resultsPaginated = vm.transactions.slice(0, 9))
          : (vm.resultsPaginated = vm.transactions.slice(
              (vm.currentx - 1) * 9,
              vm.currentx * 9
            ));
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss" src="./wallet.scss"></style>
